import React from 'react';

import Link from 'components/ui/link';
import Layout from 'layout';
import 'sass/theme/terms.scss';

const Tos = () => (
  <Layout
    metaTitle="Terms of Service | Session Replay Software"
    canonical="/terms-of-service/"
    metaDescription="Read LiveSession’s SaaS terms and conditions. Here you’ll find all terms of use in one place."
  >
    <section className="terms">
      <h1>
        LIVESESSION SOFTWARE-AS-A-SERVICE <br />
        TERMS & CONDITIONS
      </h1>

      <p className="mt-5">Last updated: 01.01.2019</p>

      <p>
        These LiveSession Software-as-a-Service (“
        <strong>SaaS</strong>
        ”) Terms and Conditions (“
        <strong>Terms and Conditions</strong>
        ”) are related to SaaS subscription between LiveSession Sp. z o.o. with its registered
        office in Wroclaw, Poland, Gen. W. Sikorskiego Street 3/2 (53-659 Wroclaw, Poland), entered
        into the registry of entrepreneurs under the KRS number 0000753134, the registry files
        maintained by the District Court for Wroclaw - Fabryczna in Wroclaw, VI Commercial Division
        of the National Court Registry with the share capital in the amount of 5.000 PLN, with the
        NIP (tax identification number) no. 8971860301 and REGON no. 38158038000000, email address:
        hello@livesession.io (“
        <strong>LiveSession</strong>
        ”), and the subscribed business client (“
        <strong>Client</strong>
        ”), which may be collectively referred as the &quot;<strong>Parties</strong>&quot; or a
        &quot;
        <strong>Party</strong>&quot; if individually.
      </p>

      <p>
        The present Terms and Conditions and all attachments hereto, together with any Subscription
        Form executed by Client, and Personal Data Processing Agreement, if applicable, all together
        constitute the LiveSession Software-as-a-Service Agreement (“
        <strong>SaaS Agreement</strong>
        ”), being B2B contract between LiveSession and Client.
      </p>

      <p>
        BY CONSENTING TO THE SAAS AGREEMENT CLIENT REPRESENTS THAT HE OR SHE HAS READ IN DETAIL THE
        SAAS AGREEMENT AND THE LIVESESSION DATA PROTECTION POLICY, UNDERSTANDS THEM, HAS BEEN
        ADVISED BY LIVESESSION ABOUT THEIR LEGAL CHARACTER AND CONSEQUENCES AND ABOUT A POSSIBLE
        NEED OF PROFESSIONAL LEGAL ADVICE BEFORE ENTERING THE SAAS AGREEMENT.
      </p>

      <p>
        THE CLIENT’S SAAS SUBSCRIPTION SHALL BE DEEMED CLIENT’S FULL AND UNCONDITIONAL AGREEMENT TO
        ALL AND ANY PRESENT TERMS AND CONDITIONS TOGETHER WITH THE ATTACHMENTS HERETO, PERSONAL DATA
        PROCESSING AGREEMENT AND DATA PROTECTION POLICY ADOPTED BY LIVESESSION AND APPLICABLE TO
        LIVESESSION SAAS.
      </p>

      <ol type="1">
        <li>
          <strong>DEFINITIONS & BASIC SAAS AGREEMENT TERMS</strong>
          <ol type="1">
            <li>
              <u>Definitions</u>:
              <ol type="a">
                <li>
                  “<strong>Effective Date</strong>” means a date on which Client subscribed to the
                  SaaS Agreement throughout the Subscription Form.
                </li>
                <li>
                  “<strong>Fee</strong>” or “<strong>Fees</strong>” mean the monthly or yearly fees
                  paid or to be paid by Client for an agreed subscription plan.
                </li>
                <li>
                  <strong>“SaaS” </strong>
                  means the subscription-based software-as-a-service made available by LiveSession
                  to Client under the terms and condition of the SaaS Agreement, which gives Client
                  a right to use a web-based analytical software, which lets Client replay website
                  visitors’ actions and get insights about them.
                </li>
                <li>
                  “<strong>Subscription Form</strong>” means any kind of form, including
                  particularly email, internet-based or written form, of an agreement between
                  LiveSession and Client to the SaaS Agreement.{' '}
                </li>
                <li>
                  “<strong>Term</strong>” means a term for which the SaaS Agreement remains valid
                  and in force between the Parties, resulting out of the agreement between the
                  Parties expressed in the Subscription Form.
                </li>
                <li>
                  &quot;<strong>Use</strong>&quot; means the ability to run, execute, display, work
                  with, use features and functions of the SaaS.
                </li>
                <li>
                  <strong>“Users”</strong> means individuals connected with Client who are
                  authorized to use the SaaS. Users may include but are not limited to authorized
                  employees, consultants, contractors and agents of Client.
                </li>
              </ol>
            </li>
            <li>
              <u>Agreement and Main Responsibilities.</u> By the SaaS Agreement LiveSession
              undertakes to provide Client with SaaS on the terms and conditions stipulated in the
              SaaS Agreement, and Client in exchange undertakes to pay Fee or Fees, as applicable
              and agreed between the Parties.
            </li>
            <li>
              <u>Commencement of SaaS</u>. The provision of SaaS shall commence no later than within
              one day from Effective Date, however no earlier than after the payment of applicable
              Fee or Fees.
            </li>
            <li>
              <u>End of SaaS</u>. The provision of SaaS shall end with the moment of termination of
              the SaaS Agreement and/or termination of subscription.{' '}
            </li>
          </ol>
        </li>
        <li>
          <strong>RIGHT TO ACCESS AND USE THE SAAS. B2B CONTRACT</strong>
          <ol type="1">
            <li>
              <u>Right to Access and Use.</u> Subject to Client’s strict compliance with the SaaS
              Agreement, as defined in these Terms and Conditions, LiveSession shall grant to
              Client, and Client accepts, a non-transferable, time-limited, non-exclusive right to
              access and Use the SaaS, in accordance with the SaaS Agreement and particularly with
              these Terms and Conditions, solely for Client’s internal purposes. Upon the
              termination of the SaaS Agreement, Client’s right to access and Use the SaaS shall
              terminate.
            </li>
            <li>
              <u>B2B Contract.</u> Client represents that he or she is a professional business
              entity, regardless whether registered or not, conducts business affairs, enters the
              SaaS Agreement for business purposes and does not act as a consumer.
            </li>
          </ol>
        </li>

        <li>
          <strong>LIMITATIONS AND RESTRICTIONS</strong>
          <ol type="1">
            <li>
              <u>Use Restrictions</u>. Client shall not: (a) modify or create any derivative work
              from the SaaS; (b) include the SaaS in any other service, product or software; (c)
              reverse assemble, decompile, reverse engineer or otherwise attempt to derive source or
              algorithms from SaaS; (d) circumvent or attempt to circumvent any technological
              mechanism that is designed to restrict access or limit copying which may be applied to
              the SaaS; (e) remove or modify any markings or any notice of LiveSession’s proprietary
              and intellectual property rights; (f) use the SaaS to store or transmit infringing,
              libelous, or otherwise unlawful material; (g) store or transmit any information in
              violation of third-party privacy rights; (h) use the SaaS to store or transmit
              malicious code; (i) interfere with or disrupt the integrity or performance of the
              SaaS; (j) attempt to gain unauthorized access to the SaaS or their related systems or
              networks; or (j) combine the SaaS with any application, software, hardware, equipment,
              product, or service, except as expressly authorized by LiveSession in advance.
            </li>
            <li>
              <u>Further limitations</u>. The rights granted to Client under the SaaS Agreement are
              also conditioned on the following: (a) Client agrees to make every reasonable effort
              to prevent unauthorized third-parties from accessing the SaaS; and (b) Client agrees
              to use of the SaaS only in accordance with this SaaS Agreement and any applicable
              laws.
            </li>
            <li>
              <u>Reverse Engineering</u>. Client must not reverse engineer the SaaS or any of its
              part, except to the extent that this restriction is expressly prohibited by applicable
              laws.
            </li>
            <li>
              <u>SaaS Updates</u>. Except for repair or replacement of the SaaS as otherwise
              expressly described in the SaaS Agreement, LiveSession may, but will not be obligated,
              under these Terms and Condition provide any updates, enhancements, improvements, new
              release or other versions of the SaaS to Client. For clarity, LiveSession reserves the
              right to designate enhanced, modified, new release and other versions of the SaaS as
              being subject to additional or separate software-as-a-service agreement terms,
              including additional fees or other payments.{' '}
            </li>
          </ol>
        </li>

        <li>
          <strong>FEES</strong>
          <ol type="1">
            <li>
              <u>Fees</u>. Client shall pay LiveSession the Fees as agreed in the Subscription Form
              and in accordance with the subscription plans set forth in{' '}
              <Link href="/terms-fees" target="_blank">
                <strong>Attachment I</strong>
              </Link>{' '}
              hereto.{' '}
            </li>
            <li>
              <u>Payment</u>. Client shall be allowed to pay any and all Fees by a debit or credit
              card only, save the Parties jointly specifically agree differently. It is LiveSession
              or a third party transfer service provider who shall have the exclusive right to
              decide on acceptance of any particular debit or credit card. LiveSession does not
              retain any credit or debit card information; Client acknowledges that any issues
              connected with technical aspects of payment process are to be resolved with a third
              party transfer service provider. Client will be charged with an appropriate Fee in
              advance, with the moment of the beginning of a subscription period. In case of failure
              of payment process, the payment process will be automatically renewed and repeated for
              7 (seven) days until successful payment. In case of ultimate failure of payment, after
              the period of seven days since the beginning of a subscription period the subscription
              is cancelled, and this SaaS Agreement terminated. Client shall be fully responsible
              for allowing the Fees to be charged automatically in advance.
            </li>
            <li>
              <u>Currency</u>. All payments shall be made in EUR or USD, provided that no other
              currency is specified in the Subscription Form. LiveSession shall not be responsible
              for any transfer, exchange, spread etc. costs related to payment of Fees, for payment
              of which Client shall be solely responsible.
            </li>
            <li>
              <u>Taxes. Net amounts.</u> All Fees are net amount. Client acknowledges and agrees
              that it shall be responsible for the payment of all taxes (including the value added
              tax, if applicable) in connection with the provision of SaaS, if and when applicable,
              in accordance with due applicable laws.
            </li>
            <li>
              <u>Changes of Subscription Plan</u>. Client may change a subscription plan at any
              time. In case of change of subscription plan during a subscription period, Client
              shall be charged with appropriate Fees proportionately to the actual period of applied
              subscription plan.
            </li>
            <li>
              <u>Surplus or Overpayment</u>. Any surplus or overpayment of Fees by Client for which
              LiveSession is responsible shall be settled for any due or future Fees and shall be
              returnable to Client within 30 days from the end of Term.
            </li>
            <li>
              <u>No Return.</u> In case of termination of Agreement during the subscription period
              no Fee or Fees which already have been paid shall be returnable to Client. Client
              shall have no claim for the return of Fee or Fees already paid, save this Agreement
              specifically provides differently.
            </li>
          </ol>
        </li>

        <li>
          <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
          <ol type="1">
            <li>
              <u>Sole ownership</u>. LiveSession will, as between LiveSession and Client and subject
              to the limited Client use rights set forth in these Terms and Conditions, have and
              retain sole and exclusive ownership of all right, title and interest in and to any
              product, software, service or deliverables provided by LiveSession pursuant to the
              SaaS Agreement, including but not limited to, all patents, copyrights, trade secrets
              mask works, trademarks and other proprietary rights it may enjoy. LiveSession will
              further have sole ownership of all improvements and modifications to any product,
              software, service or deliverables provided by LiveSession pursuant to the SaaS
              Agreement, whether such improvements or modifications were based upon Client feedback
              and/or made by either Party alone or in cooperation with each other. No rights are
              granted hereunder to Client under any patents, design models, copyright, know-how,
              trade secrets and trademarks except as are incidental only to the Use of SaaS by
              Client.
            </li>
            <li>
              <u>No Compensation</u>. Client accepts that any increase in the value of LiveSession’s
              intellectual property resulting from Client&quot;s activities shall not give rise to
              any claim for compensation to Client.
            </li>
            <li>
              <u>No Registration Rights.</u> Client represents that it has not sought nor obtained
              and that he or she shall not seek to obtain registration in its own name of any of
              LiveSession’s intellectual property and undertakes to cease use of LiveSession’s
              intellectual property upon termination of the SaaS Agreement.
            </li>
            <li>
              <u>References</u>. Whenever Client shall make a reference to its relationship with
              LiveSession whether in advertising or otherwise, Client shall describe its
              relationship only as a user of SaaS. Any other use by Client of LiveSession’s trade
              name and/or trademark must be previously approved by a duly authorized officer of
              LiveSession.
            </li>
          </ol>
        </li>

        <li>
          <strong>LIMITED SAAS WARRANTY. SOLE REMEDIES</strong>
          <br />
          LiveSession guarantees and warrants that it has the authority to grant to Client the right
          to access and Use the SaaS. Further, subject to Client’s compliance with all and any rules
          and terms of SaaS Agreement, LiveSession warrants that the SaaS will provide substantially
          the same functions, features and characteristics as described in the definitions to these
          Terms and Conditions and applicable attachment to these Terms and Conditions, if any. The
          Client’s exclusive remedies for breach of this warranty are hereby agreed and exhaustively
          enlisted in the present section. If the SaaS does not provide substantially the same
          functions, features and characteristics as described in the definitions to these Terms and
          Conditions and applicable attachment to these Terms and Conditions, if any, LiveSession
          will, at its sole option, either (i) modify the SaaS; or (ii) provide a workaround
          solution that will reasonably meet Client’s requirements. If neither of these options is
          commercially feasible as determined by LiveSession at its sole discretion, either Party
          may terminate the SaaS Agreement upon 30 (thirty) days prior notice.
        </li>

        <li>
          <strong>WARRANTY DISCLAIMER. LIABILITY</strong>
          <ol type="1">
            <li>
              <u>SERVICE “AS IS”.</u> EXCEPT AS EXPRESSLY SET FORTH IN THE SAAS AGREEMENT, THE SAAS
              IS PROVIDED “AS IS,” AND LIVESESSION DISCLAIMS ALL EXPRESS OR IMPLIED WARRANTIES,
              CONDITIONS OR REPRESENTATIONS INCLUDING, WITHOUT LIMITATION, (I) IMPLIED WARRANTIES OF
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, (II) WARRANTIES OF TITLE AND
              AGAINST INFRINGEMENT AND (III) WARRANTIES ARISING FROM A COURSE OF DEALING, USAGE OR
              TRADE PRACTICE. TO THE EXTENT ANY IMPLIED WARRANTY CANNOT BE EXCLUDED, SUCH WARRANTY
              IS LIMITED IN DURATION TO THE EXPRESS WARRANTY PERIOD.
            </li>
            <li>
              <u>LIMITATION OF LIABILITY</u>. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW:
              <ol type="a">
                <li>
                  LIVESESSION SHALL NOT BE LIABLE, WHETHER IN CONTRACT, NEGLIGENCE, TORT, OR ON ANY
                  OTHER BASIS, FOR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES, LOSS OR
                  CORRUPTION OF DATA, OR LOSS OF REVENUE, SAVINGS OR PROFITS, LOSS OF BUSINESS, LOSS
                  OF OPPORTUNITY, LOSS OF GOODWILL, LOSS OF REPUTATION OR EXEMPLARY DAMAGES ARISING
                  OUT OF OR IN CONNECTION WITH THE PROVISION, USE, PERFORMANCE, FAILURE OR
                  INTERRUPTION IN THE OPERATION OF THE SAAS, EVEN IF LIVESESSION IS ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES;
                </li>
                <li>
                  LIVESESSION WILL NOT BE LIABLE FOR ANY CLAIM MADE THE SUBJECT OF A LEGAL
                  PROCEEDING MORE THAN TWO (2) YEARS AFTER THE CAUSE OF ACTION ASSERTED IN SUCH
                  CLAIM AROSE;
                </li>
                <li>
                  NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THE SAAS AGREEMENT, THE AGGREGATE
                  LIABILITY OF LIVESESSION AND ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES OR OTHER
                  REPRESENTATIVES, ARISING IN ANY WAY IN CONNECTION WITH THE SAAS AGREEMENT—WHETHER
                  UNDER CONTRACT LAW, TORT LAW, WARRANTY OR OTHERWISE—WILL NOT EXCEED THE TOTAL
                  LICENCE FEES PAID BY CLIENT TO LIVESESSION
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <strong>CLIENT INDEMNIFICATION</strong>
          <br />
          To the maximum extent permitted by the applicable laws, Client shall indemnify and hold
          LiveSession harmless from and against any and all liabilities, costs, expenses and damages
          (including without limitation reasonable attorney’s fees) suffered or incurred by
          LiveSession as a result of (a) Client’s breach of its obligations under the SaaS
          Agreement; (b) Client’s failure to comply with applicable laws; or (c) Client’s negligence
          or intentional torts.
        </li>

        <li>
          <strong>CONFIDENTIALITY </strong>
          <br />
          All and any information supplied by LiveSession in connection with the execution and
          performance of the SaaS Agreement and designated by LiveSession as confidential, or which
          Client should reasonably believe to be confidential based on its subject matter or the
          circumstances of disclosure, shall be treated by Client as a strictly confidential
          information in a reasonable and appropriate manner and may be used by Client only as
          necessary to benefit from or to perform its obligations under the SaaS Agreement. Such
          confidential information cannot be used by Client for any other purpose, unless expressly
          agreed by LiveSession. The confidential information does not include or embrace
          information which is: (a) publicly known; (b) already known to Client; (c) lawfully
          disclosed by a third party; (d) independently developed; or (e) disclosed pursuant to a
          legal requirement or order. Client may reasonably disclose the confidential information on
          a need-to-know basis to its contractor&quot;s, agents and affiliates who agree to
          confidentiality and non-use terms that are substantially similar to these terms. For the
          avoidance of doubts, any trainings, instructions, tutorials, technical support or IT
          information provided by LiveSession shall be deemed to be the confidential information.
        </li>

        <li>
          <strong>PERSONAL INFORMATION / PERSONAL DATA OF CLIENT</strong>
          <ol type="1">
            <li>
              <u>Consent to Data Protection Policy</u>. By agreeing to the SaaS Agreement, Client
              fully and unconditionally agrees and accepts the{' '}
              <strong>LiveSession Data Protection Policy</strong>.{' '}
            </li>
            <li>
              <u>Data Processing</u>. LiveSession shall comply with any applicable privacy and
              personal data protection laws, particularly while processing personal data and/or
              information of Users. The rules of data processing are stipulated in the LiveSession
              Data Protection Policy.
            </li>
          </ol>
        </li>
        <li>
          <strong>PROCESSING PERSONAL INFORMATION / DATA OF CLIENT’S CUSTOMERS</strong>
          <ol type="1">
            <li>
              <u>Acknowledgment</u>. Client fully acknowledges that LiveSession SaaS is the tool
              which is based on collecting, tracking, analyzing and using personal data, dedicated
              to collect, track, analyze and use personal data of persons visiting or otherwise
              using Client’s websites, web services etc.{' '}
            </li>
            <li>
              <u>CLIENT AS DATA ADMINISTRATOR. RESPONSIBILITY.</u> THE PARTIES JOINTLY AGREE, AND
              CLIENT ACKNOWLEDGES THAT IT IS THE SOLE RESPONSIBILITY OF CLIENT TO ENSURE THAT ANY
              AND ALL PERSONAL INFORMATION AND/OR PERSONAL DATA OF CLIENT’S CUSTOMERS, WEBSITE
              VISITORS, WEB SERVICES’ USERS AND ANY OTHER PERSONS CONCERNED ARE PROCESSED LEGALLY.
              PARTICULARLY, PARTIES JOINTLY AGREE THAT IT IS CLIENT WHO IS THE ADMINISTRATOR
              (COLLECTOR) OF PERSONAL INFORMATION AND/OR PERSONAL DATA PROCESSED SO, WHILE
              LIVESESSION, IF APPLICABLE, SHALL BE TREATED SOLELY AS PERSONAL INFORMATION AND/OR
              PERSONAL DATA PROCESSOR, ACTINGING SOLELY AND EXCLUSIVELY ON SPECIFIC ORDER AND
              REQUEST OF CLIENT. IT IS SOLE CLIENT’S RESPONSIBILITY TO DETERMINE SCOPE AND AIM OF
              PERSONAL INFORMATION AND/OR DATA PROCESSING AND TO FULFILL ANY AND ALL LEGAL
              OBLIGATIONS CONNECTED WITH BEING THE ADMINISTRATOR OF PERSONAL INFORMATION AND/OR DATA
              WHICH MAY APPLY.
            </li>
            <li>
              <u>Personal Data Processing Agreement</u>. By consenting to the SaaS Agreement Client
              hereby agrees and executes the Personal Data Processing Agreement constituting the{' '}
              <Link href="/data-processing-agreement" target="_blank">
                <strong>Attachment III</strong>
              </Link>
              &nbsp;to these Terms and Conditions.
            </li>
          </ol>
        </li>

        <li>
          <strong>SUSPENSION OR RESTRICTION OF SERVICE; MAINTENANCE</strong>
          <ol type="1">
            <li>
              <u>Suspension and Restriction</u>. For the benefit of all LiveSession users and
              clients, LiveSession may suspend or restrict the SaaS at any time, until further
              notice to Client, and provide notice of such suspension or restriction to Client as
              soon as reasonably practicable if (i) the provision of the SaaS would cause
              LiveSession to be in breach of law; or (ii) LiveSession reasonably determines that the
              SaaS must be suspended in order to (a) carry out planned or unplanned maintenance,
              repair or upgrading; or (b) prevent substantial harm to the SaaS arising from any
              activity originating from or through Client’s Use of the SaaS that could disrupt the
              SaaS. LiveSession will notify Client about the suspension and restriction as soon as
              reasonably practicable.
            </li>
            <li>
              <u>Maintenance</u>. LiveSession shall use its commercially reasonable efforts to
              schedule needed and predictable maintenance of SaaS using reasonable procedures to
              minimize SaaS service outages, as well as it will use reasonable efforts to ensure
              that there is minimum disruption to the SaaS in case of any suspension and restriction
              and shall use its commercially reasonable efforts to promptly reinstate the SaaS in
              any of such cases. Planned maintenance whenever reasonably practicable will be
              performed during off-business hours between 12:00 p.m. to 6:00 a.m. (GMT -4) or during
              the weekends, with as little disruption to Client’s Use of the SaaS as possible, and
              unplanned maintenance, whenever reasonably practicable, shall also be performed during
              off-business hours between 6:00 p.m. and 6:00 a.m. (GMT -4).
            </li>
            <li>
              <u>LIABILITY LIMITATION</u>. LIVESESSION SHALL NOT BE LIABLE TO CLIENT OR TO ANY THIRD
              PERSON FOR ANY LOSS RESULTING FROM OR IN CONNECTION WITH A SUSPENSION, WITHDRAWAL,
              MAINTENANCE DOWNTIME OR RESTRICTION OF THE SAAS.{' '}
            </li>
          </ol>
        </li>

        <li>
          <strong>CLIENT TECHNICAL RESPONSIBILITIES AND MINIMAL REQUIREMENTS</strong>
          <ol type="1">
            <li>
              <u>Client’s Responsibilities</u>. Client shall be responsible, at Client’s sole
              expense, for acquiring, selecting, securing and maintaining any equipment or services
              needed to connect to, access or Use the SaaS, including for example, but without any
              limitation, modems, hardware, servers, software, operating systems etc.
            </li>
            <li>
              <u>Familiarity with Minimal Technical Requirements</u>. Client represents that it has
              been advised about the minimal technical requirements needed to access and Use SaaS
              and meeting these minimal technical requirements remains a sole responsibility of
              Client, in accordance with the section above.
            </li>
            <li>
              <u>Minimal Technical Requirements</u>. The minimal technical requirements for the SaaS
              are stipulated in the{' '}
              <Link href="/terms-minimal-requirements" target="_blank">
                <strong>Attachment II</strong>
              </Link>{' '}
              hereto.
            </li>
          </ol>
        </li>

        <li>
          <strong>TERM & TERMINATION</strong>
          <ol type="1">
            <li>
              <u>Term of Agreement</u>. The Term of the SaaS Agreement is indefinite, unless it is
              set forth otherwise in the Subscription Form.
            </li>
            <li>
              <u>Termination for Convenience.</u> Without prejudice to any other termination rights
              prescribed herein, either Party may terminate the SaaS Agreement without stating a
              reason by canceling subscription. Upon a termination for convenience, Client&quot;s
              right to access and Use the SaaS Agreement will terminate immediately after the
              cancelation notice is effectively delivered to the other Party if no other moment of
              termination is indicated by LiveSession. During the notice period, if any, Client is
              obligated to continue to pay the appropriate Fees. The Parties explicitly agree that
              in case of termination by LiveSession Client shall have no right and waives and
              possible right to any damages incurred due to the termination.
            </li>
            <li>
              <u>Survival.</u> Any provision of the SaaS Agreement that contemplates performance or
              observance after a termination of the SaaS Agreement will survive termination and
              continue in full force and effect for the period so contemplated including, but not
              limited to, provisions relating to warranty disclaimers, intellectual property
              ownership, payment terms, confidentiality, waiver of consequential damages, and cap on
              liability.
            </li>
          </ol>
        </li>

        <li>
          <strong>MISCELLANEOUS</strong>
          <ol type="1">
            <li>
              <u>Entire Agreement</u>. These Terms and Conditions and attachments hereto together
              with the Subscription Form constitute the entire agreement between the Parties with
              respect to the subject matter hereof and supersede all previous agreements pertaining
              to such subject matter. All prior agreements, representations, warranties, statements
              etc. are superseded hereby. Client represents and acknowledges that it has not relied
              on any representation or warranty (other than those explicitly set forth in these
              Terms and Conditions) in connection with its signing of the SaaS Agreement.
            </li>
            <li>
              <u>Governing Law</u>. The SaaS Agreement and performance hereunder will be governed by
              and construed in accordance with the laws of Poland. For the avoidance of any doubts,
              the United Nations Convention on Contracts for the International Sale of Goods shall
              not be applicable to the SaaS Agreement.
            </li>
            <li>
              <u>Dispute Resolution.</u> Any dispute, controversy or claim arising out of or
              relating to the SaaS Agreement, or the breach, termination or invalidity thereof,
              shall first be attempted to be settled by direct discussion between LiveSession and
              Client. If such discussion does not result in settlement within 30 (thirty) days of
              its notification by one Party to the other, then such dispute, controversy or claim
              shall be settled by an appropriate court having jurisdiction over the registered
              office of LiveSession in Poland.{' '}
            </li>
            <li>
              <u>No Solicitation</u>
              <i>. </i>
              During the Term and continuing for three years after the termination of the SaaS
              Agreement, Client shall not, directly or indirectly, solicit or recruit any
              LiveSession’s employees or officers to terminate his or her employment with
              LiveSession.{' '}
            </li>
            <li>
              <u>Client Assignment</u>
              <i>. </i>
              Client must not assign or transfer its interests, rights or obligations under the SaaS
              Agreement by any agreement, merger, consolidation, operation of law or otherwise
              without the prior consent of an authorized representative of LiveSession. Any attempt
              to assign the SaaS Agreement by Client to the contrary of this provision shall be
              deemed to be null and void.{' '}
            </li>
            <li>
              <u>LiveSession Assignment</u>. LiveSession may assign or delegate the performance of
              part or all of its obligations under the SaaS Agreement to any third-party upon notice
              to Client, provided however that LiveSession shall be responsible to Client for the
              performance of the obligations so assigned or delegated.
            </li>
            <li>
              <u>Publicity</u>
              <i>. </i>
              Unless otherwise provided otherwise in a separate agreement between the Parties, each
              Party may issue a press release following the execution of the SaaS Agreement. Each
              Party hereby consents to the other Party&quot;s use of its name, logo and trademark on
              its website and for corporate and financial presentations.
            </li>
            <li>
              <u>Electronic Services Act Note. </u>
              This SaaS Agreement constitutes the terms and conditions of providing electronic
              services in the meaning of Article 8 of Act of 18.07.2002 on providing electronic
              services (Official Gazette 2002 No. 144 section 1204). LiveSession hereby informs and
              Client acknowledges that (a) Client must not use LiveSession SaaS or any of its part
              to provide, transmit, transfer etc. an unlawful content; (b) any Client’s complaint
              shall be resolved in line with the dispute resolution means prescribed by this SaaS
              Agreement; (c) the SaaS Agreement can be entered into only by electronic means through
              signing a Subscription Form.
            </li>
            <li>
              <u>Force Majeure</u>
              <i>. </i>
              LiveSession shall not be responsible for any failure or delay in performing any
              obligation under the SaaS Agreement if such failure or delay is due to a cause beyond
              the LiveSession’s reasonable control, including, but not limited to acts of God,
              flood, fire, volcano, war, third-party suppliers (including particularly those
              providing hosting and IT software, hardware or infrastructure to LiveSession), labor
              disputes, economic and commercial crises, embargos, governmental acts, riots,
              substantial changes of law, terrorist and hackers’ attacks, destruction of LiveSession
              servers by any third party, unpredictable breakdown of LiveSession servers (“
              <strong>Force Majeure</strong>
              ”). In case a Force Majeure occurs, it shall not affect the Client’s obligations to
              pay the Fees. The parties jointly agree that the Client’s obligation to pay the Fees
              is not contingent on any Force Majeure event.
            </li>
            <li>
              <u>Compliance with Laws</u>. Each Party will comply with all applicable international
              and national laws and regulations in the performance of all of activities under the
              SaaS Agreement. Client will not, directly or indirectly, export or transmit the SaaS
              or its part to any country to which such export or transmission is prohibited by any
              applicable regulation or statute.{' '}
            </li>
            <li>
              <u>Authentic Language. Interpretation.</u>
              <i> </i>
              The SaaS Agreement is executed in the English language. The English language shall be
              the sole and controlling language used in interpreting or construing the meaning of
              the SaaS Agreement. Any principle of construction or rule of law that provides that an
              agreement be construed against the drafter of the agreement shall not apply to the
              SaaS Agreement.{' '}
            </li>
            <li>
              <u>Headings</u>
              <i>. </i>
              Headings used in the SaaS Agreement are intended for convenience or reference only and
              will not control or affect the meaning or construction of any provision of the SaaS
              Agreement.{' '}
            </li>
            <li>
              <u>Electronic form.</u> The SaaS Agreement may be executed in electronic form, unless
              agreed otherwise by the Parties.{' '}
            </li>
            <li>
              <u>Beneficiaries. No exclusivity.</u> The SaaS Agreement is entered into only for the
              benefit of Client and LiveSession. No other person or entity will have the right to
              make any claim or assert any right hereunder, and no other person or entity will be
              deemed a beneficiary of the SaaS Agreement
              <i>.</i> At the same time the Parties confirm and acknowledge that the SaaS is
              provided on a non-exclusive basis and LiveSession may provide the SaaS to any other
              parties at their sole discretion.
            </li>
            <li>
              <u>No Contingency On Future Releases.</u> Client acknowledges and agrees that its
              purchases under this SaaS Agreement are neither contingent upon the delivery of any
              future functionality or features nor dependent on any oral or written public comments
              made by LiveSession regarding future functionality or features.
            </li>
            <li>
              <u>No Disparagement.</u> Client shall not in any way, directly or indirectly,
              disparage the SaaS, LiveSession, or any other products, services or software made or
              commercialized by LiveSession.
            </li>
            <li>
              <u>Discrepancies</u>. In case of discrepancy between these Terms and Conditions and
              one or more of its attachments hereto, the attachments shall prevail. In case of
              discrepancy between these Terms and Conditions or any its attachments and the
              Subscription Form, the Subscription Form shall prevail.
            </li>
            <li>
              <u>Severability</u>. Any provision of the SaaS Agreement, which is or may be void or
              unenforceable under the applicable law shall be deemed severable to the extent of such
              invalidity or unenforceability and shall not affect any other provisions hereof.
            </li>
            <li>
              <u>Waiver</u>. Any failure to enforce any provision of the SaaS Agreement shall not
              constitute a waiver. No waiver of any provision of the SaaS Agreement or consent to
              any departure from it by any Party shall be effective unless it is in writing. A
              waiver or consent will be effective only for the specific purpose for which it is
              given. No default or delay on the part of any Party in exercising any rights, powers
              or privileges operates as a waiver of any right, nor does a single or partial exercise
              of a right preclude any exercise of other rights, powers or privileges.
            </li>
            <li>
              <u>Notices</u>. The Parties jointly agree that any notice under the SaaS Agreement, or
              required by law, may be made in written, paper or electronic form, including, but not
              limited to, an email form or via an appropriate contact form on LiveSession’s website.
              All notices and communications shall be made to the addresses and contact details
              provided by the Parties in the Subscription Form. Notices will be considered to have
              been given (a) a next working day after their delivery, in case of electronic and
              email communication, and (b) at the time of their actual receipt. Either Party may
              change its address for notices by duly made notification to the other Party. For the
              purpose of this section, a working day means any day from Monday to Friday with an
              exclusion of bank or state holidays under the law governing the SaaS Agreement.
            </li>
          </ol>
        </li>
      </ol>

      <h2 style={{ textAlign: 'left' }}>Attachments:</h2>
      <ul>
        <li>
          <Link href="/terms-fees" target="_blank">
            <strong>ATTACHMENT I – FEES AND SUBSCRIPTION PLANS</strong>
          </Link>{' '}
        </li>
        <li>
          <Link href="/terms-minimal-requirements" target="_blank">
            <strong>ATTACHMENT II – MINIMAL TECHNICAL REQUIREMENTS</strong>
          </Link>
        </li>
        <li>
          <Link href="/data-processing-agreement" target="_blank">
            <strong>ATTACHMENT III - PERSONAL DATA PROCESSING AGREEMENT</strong>
          </Link>
        </li>
      </ul>
    </section>
  </Layout>
);

export default Tos;
